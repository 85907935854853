<template>
  <el-card shadow="hover" class="cursor-pointer h-full">
    <div class="flex flex-row w-full h-full">
      <div class="type-image flex bg-blue-100 rounded-lg">
        <span class="m-auto uppercase"> {{ project.title ? project.title.charAt(0) : 'N/D' }} </span>
      </div>
      <div class="ml-4 w-full flex flex-col overflow-hidden">
        <el-tooltip
            :enterable="false"
            :content="project.title"
            placement="top"
        >
          <input class="font-bold" @click.stop v-model="project.title"
                 @input="debounceEdit('title', $event.target.value)">
        </el-tooltip>
        <el-tooltip
            :enterable="false"
            :content="project.description"
            placement="top"
        >
          <input class="text-sm" @click.stop v-model="project.description"
                 @input="debounceEdit('description', $event.target.value)">
        </el-tooltip>
      </div>
      <div>
        <i @click.stop="$emit('delete-project')" class="el-icon-delete"></i>
      </div>
    </div>
    <el-divider class="my-4"/>
    <div class="flex flex-row">
      <span class="text-xs font-bold mr-1">Configurazione:</span>
      <span class="text-xs">{{project.template_name}}</span>
    </div>
    <div class="flex flex-row justify-between mt-2">
      <div class="grid grid-cols-1 gap-1">
        <time class="font-bold text-sm">{{ format(utcToZonedTime(project.created_at) , "dd/MM/yyyy", { timeZone: 'Europe/Paris' }) }}</time>
        <span class="text-xs text-gray-600">Data creazione</span>
      </div>
      <div class="grid grid-cols-1 gap-1">
        <time class="font-bold text-sm">{{ format(utcToZonedTime(project.updated_at), "dd/MM/yyyy", { timeZone: 'Europe/Paris' }) }}</time>
        <span class="text-xs text-gray-600">Ultima modifica</span>
      </div>
    </div>
  </el-card>
</template>

<script>
import {format, utcToZonedTime} from 'date-fns-tz'

export default {
  props: {
    project: {type: Object, required: true}
  },
  data() {
    return {
      format, utcToZonedTime,
      debounce: null
    }
  },
  methods: {
    debounceEdit(prop, value) {
      let payload = {
        [prop]: value
      }
      this.$emit('edit', this.project.id, payload)
    }
  },
};
</script>

<style>
.type-image {
  min-width: 40px;
  min-height: 40px;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

</style>
