<template>
  <div class="p-4 w-full">
    <div class="p-4 z-10 border-t-2 border-gray-200 flex flex-row justify-between bg-white fixed left-0 right-0"
         style="top:4rem">
      <div class="w-2/3 inline-flex">
        <el-input class="pr-2" prefix-icon="el-icon-search" v-model="queryParams.text" @input="debounceSearch"
                  placeholder="Cerca..." clearable></el-input>
        <el-select v-if="user.role.key ==='admin'" @change="onUserSelectChange" clearable v-model="queryParams.user_ids"
                   multiple placeholder="Filtra per utenti" class="pl-2 w-full">
          <el-option
              v-for="user in users"
              :key="user.id"
              :label="user.name + ' ' + user.surname"
              :value="user.id">
          </el-option>
        </el-select>
      </div>
      <el-button @click="openNewProjectModal" type="primary"><i class="el-icon-plus pr-2"></i>Nuovo progetto</el-button>
    </div>
    <section class="mt-16" v-loading="isLoading">
      <div v-for="(computedProject,index) in computedProjects" :key="index" class="mt-8">
        <h1 class="text-xl font-bold" v-if="computedProject[0].is_mine">I miei progetti</h1>
        <h1 class="text-xl font-bold" v-else>{{
            computedProject[0].user.name + " " + computedProject[0].user.surname
          }}</h1>
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 mt-4">
          <ProjectCard
              v-for="(project, index) in computedProject"
              :project="project"
              :key="project.id"
              @click.native="onProjectClick(project)"
              @edit="onUpdateProject"
              @delete-project="
                    openDeleteConfirmationDialog(
                      deleteProject,
                     'il progetto',
                     project.id,
                     index
                    )
                  "
          />
        </div>
      </div>
    </section>
    <div class="flex flex-row w-full py-12">
      <span v-if="!projects.length && !isLoading"
            class="text-gray-600 m-auto">Non sono presenti progetti,
        <a class="text-blue-700 underline cursor-pointer" @click="openNewProjectModal">creane uno nuovo</a>
      </span>
    </div>
    <div class="text-center mt-8">
      <el-button v-if="loadMoreProjects" :loading="isLoading" type="primary" @click="loadMore">Carica altri</el-button>
    </div>
  </div>
</template>

<script>
import ProjectCard from '@/components/cards/ProjectCard.vue'
import projectModuleConstants from "@/constants/projectModuleConstants";
import projectsServices from '@/modules/projects/services/projects.services';
import confirmService from '@/services/confirmService';
import NewProjectForm from "../../../components/forms/NewProjectForm";
import {mapGetters} from 'vuex'
import {mapValues, groupBy, omit} from 'lodash';


export default {
  name: 'Projects',
  components: {ProjectCard},
  data() {
    return {
      projectModuleConstants,
      textFilter: '',
      projects: [],
      queryParams: {
        per_page: 10,
        page: 1,
        with: "user",
        text: "",
        user_ids: []
      },
      loadMoreProjects: false,
      isLoading: true,
      noProjectMessage: '',
      debounce: null,
      debounceEdit: null,
      newProjectModal: null,
    }
  },
  computed: {
    computedProjects() {
      return mapValues(groupBy(this.projects, 'user_id'),
          clist => clist.map(car => omit(car, 'user_id')));
    },
    ...mapGetters({
      users: 'projects/users',
      user: 'auth/user',
    }),
  },
  mounted() {

    // Check if there's a pid
    if (this.$route.query && this.$route.query.pid) {
      this.$router.push(`/projects/${this.$route.query.pid}`);
    } else {
      /**
       * @desc get list of projects
       */
      this.getProjectsList()

      /**
       * @description Get users data only in case there is no user data already on store
       */
      if (!this.users || !this.users.length) {
        this.getUsers()
      }
    }
  },
  methods: {
    /**
     * @desc Get users
     * @returns {Promise<void>}
     */
    async getUsers() {
      this.isLoading = true;
      await projectsServices.getUsers().then((usersData) => {
        this.$store.dispatch('projects/setUsers', usersData);
      }).catch((error) => {
        this.$notificationService.error(error)
      }).finally(() => this.isLoading = false)
    },
    getProjectsList() {
      this.isLoading = true
      projectsServices.getProjects(this.queryParams).then((response) => {
        this.projects = [...this.projects, ...response.data.data]
        let meta = response.data.meta
        this.loadMoreProjects = meta.current_page !== meta.last_page;
        this.queryParams = {
          ...this.queryParams,
          page: meta.current_page,
        }
      }).finally(() => this.isLoading = false)
    },
    onProjectClick(project) {
      this.$store.dispatch('projects/setSelectedProject', project);
      this.$router.push(`/projects/${project.id}`);
    },
    openDeleteConfirmationDialog(successFunction, subject, data, index) {
      confirmService.openWarningConfirm(successFunction, data, index, 'Attenzione', `Questo rimuovere ${subject}. Continuare?`);
    },
    deleteProject(id, index) {
      projectsServices.deleteProject(id).then(() => {
        this.$notificationService.success('Il progetto è stato cancellato con successo!');
        this.projects.splice(index, 1)
      }).catch((error) => {
        this.$notificationService.error(error)
      })
    },
    onUpdateProject(project_id, data) {
      clearTimeout(this.debounceEdit)
      this.debounceEdit = setTimeout(() => {
        projectsServices.updateProject(project_id, data).then(() => {
          this.$notificationService.success('Il progetto aggiornato con successo!');
        }).catch((error) => {
          this.$notificationService.error(error)
        })
      }, 600)
    },
    onUserSelectChange() {
      this.projects = [];
      this.queryParams.page = 1
      this.getProjectsList()
    },
    openNewProjectModal() {
      this.newProjectModal = this.$modal.open('Nuovo progetto', NewProjectForm, null, this._addNewProject, () => {
      }, true, 'Crea progetto');
    },
    async _addNewProject() {
      this.newProjectModal.startLoading();
      let data = this.newProjectModal.getData()
      projectsServices.addProject(data).then((response) => {
        let projectData = response.data.data;
        this.$notificationService.success('Progetto aggiunto con successo!')
        this.onProjectClick(projectData)
        this.newProjectModal.close()
      }).catch((error) => {
        this.$notificationService.error(error)
      }).finally(() => this.newProjectModal.stopLoading())
    },
    loadMore() {
      this.queryParams.page = this.queryParams.page + 1;
      this.getProjectsList()
    },
    debounceSearch() {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.queryParams.page = 1;
        this.projects = [];
        this.getProjectsList()
      }, 600)
    }
    // way to filter frontend side
    // querySearchAsync(queryString) {
    //   var links = this.projects;
    //   this.filteredProjects = queryString ? this.projects.filter(this.createFilter(queryString)) : links;
    // },
    // createFilter(queryString) {
    //   return (link) => {
    //     return (link.title.toLowerCase().indexOf(queryString.toLowerCase()) === 0 || link.description.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
    //   };
    // },
  }
}
</script>

<style>

</style>
