<template>
  <el-form v-loading="isLoading" label-position="top" label-width="100px" :rules="rules" ref="projectForm"
           :model="form">
    <el-form-item label="Titolo" prop="title">
      <el-input v-model="form.title"></el-input>
    </el-form-item>
    <el-form-item label="Descrizione" prop="description">
      <el-input v-model="form.description"></el-input>
    </el-form-item>
    <el-form-item label="Scelta della configurazione di partenza" prop="template_id">
      <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
        <TemplateCard @click="(id) => form.template_id = id" :selected="form.template_id === template.id"
                       v-for="template in templates" :key="template.id" :template="template"/>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
  import projectsServices from "@/modules/projects/services/projects.services";
  import TemplateCard from "../cards/templateCard";

  export default {
    name: "NewProjectForm",
    components: {TemplateCard},
    data() {
      return {
        isLoading: false,
        form: {
          title: null,
          description: null,
          template_id: null
        },
        templates: [],
      }
    },
    computed: {
      rules() {
        return {
          title: [{required: true, message: 'Il titolo è obbligatorio', trigger: 'change'}],
          description: [{required: true, message: 'La descrizione è obbligatoria', trigger: 'change'}],
          template_id: [{required: true, message: 'Template è obbligatorio', trigger: 'change'}],
        }
      }
    },
    watch: {
      form: {
        deep: true,
        handler() {
          var vm = this;
          vm.$emit('change', vm.form); // Emit change event for having data it in v-model
          this.$refs.projectForm.validate((valid) => {
            vm.$emit('validate', valid);
          });
        },
      },
    },
    created() {
        this._getTemplates();
    },
    methods: {
      _getTemplates() {
        this.isLoading = true;
        projectsServices.getTemplates().then((response) => {
          this.templates = response.data.data
        }).catch(() => {
          this.$notificationService.error('Si è verificato un errore durante il recupero dei template')
        }).finally(() => this.isLoading = false)
      }
    }
  }
</script>

<style lang="scss" scoped>
.el-form-item::v-deep{
  margin-bottom: 10px;

  label{
    line-height: 20px;
    padding-bottom: 5px;
  }
}
</style>
